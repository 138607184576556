export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'no_transaction', label: "No Transaction", _style:'min-width:100px' },
    { key: 'user_id', label: "User", _style:'min-width:100px' },
    { key: 'test_bundle_id', label: "Test Bundle", _style:'min-width:100px' },
    { key: 'amount', label: "Amount", _style:'min-width:100px' },
    { key: 'payment_method', label: "Payment Method", _style:'min-width:100px' },
    { key: 'payment_channel', label: "Payment Channel", _style:'min-width:100px' },
    { key: 'status', label: "Status", _style:'min-width:100px' },
    { key: 'note', label: "Note", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
